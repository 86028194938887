export const transformToFloatString = (value: string) =>
  value.replace(',', '.');

export function transformToPercentString(
  value: string,
  marginType?: number
): string {
  if (typeof value === 'string' && !value.length) return '—';

  return (
    `${parseFloat(String(value).replace(',', '.')).toFixed(2)} ` +
    transformToMarginTypeName(marginType ?? 0)
  );
}

export function transformToDecimalString(value: string): string {
  return String(parseFloat(value));
}

export const transformToSaveDecimalString = (value: string): string =>
  String(parseFloat(value).toFixed(2));

export const transformToQuotesDecimalString = (value: string): string => {
  const parse = String(parseFloat(value).toFixed(2));
  return parse === 'NaN' ? '—' : parse;
};

export const transformToQuotesIntegerString = (value: string): string => {
  const parse = String(parseFloat(value));
  return parse === 'NaN' ? '—' : parse;
};

export const transformToMarginTypeName = (marginType: number): string => {
  switch (marginType) {
    case 0:
      return '%';
    case 1:
      return 'DKK/KG';
    case 2:
      return 'DKK/MT';
    case 3:
      return 'EUR/KG';
    case 4:
      return 'EUR/MT';
    case 5:
      return 'GBP/KG';
    case 6:
      return 'GBP/MT';
    case 7:
      return 'NOK/KG';
    case 8:
      return 'NOK/MT';
    case 9:
      return 'SEK/KG';
    case 10:
      return 'SEK/MT';
    case 11:
      return 'USD/KG';
    case 12:
      return 'CAD/KG';

    default:
      return '';
  }
};
